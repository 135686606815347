<template>
    <div class="link-intercept-ctn">
        <div class="link-card">
            <div>
                <img class="logo" style="width: 120px;" src="../../../assets/kyx_logo-transparant.png" />
            </div>
            <div class="link-content">
                <div style="font-size: 18px; color: rgba(0, 0, 0, 0.65);">
                    即将离开科颐轩科研社区， 请注意账号财产安全
                </div>
                <div style="color: rgba(0, 0, 0, 0.45); font-size: 16px; margin-top: 20px;">
                    {{  link  }}
                </div>
                <div style=" width: 100%; height: 1px; background: #ebebeb; margin-top: 20px; ">

                </div>
                <div style="margin-top: 20px; text-align: right;">
                    <a-button type="primary" @click="handleContinue"> 继续访问 </a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue-demi';


export default {
    props: {

    },
    setup(props, context) {
        const { $route, $router } = context.root;

        const link = computed(() => {
            return decodeURI($route.query.url);
        })

        const handleContinue = () => {
            window.open(link.value, "_self")
        }
        return {
            link,
            handleContinue
        }
    }
}
</script>

<style lang="less" scoped>
.link-intercept-ctn {
    min-height: 100vh;
    padding: 200px;
    display: flex;
   // align-items: center;
    justify-content: center;
    background: #f6f6f6;
    .link-card {
        //margin-top: ;
    }
    .link-content {
        margin-top: 16px;
        width: 600px;
        padding: 32px;
        border: 1px solid #ebebeb;
        background: #fff;
    }
}
</style>